.profileContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between profile image and name */
}

.profilePic {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.userName {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  cursor: pointer;
  line-height: 1.2;
}

.upgradeButton {
  margin-top: 5px;
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.downloadExtension {
  margin-top: 5px;
  padding: 6px 12px;
  background-color: #07a870;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upgradeButton:hover {
  background-color: #0056b3;
}
