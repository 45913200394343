/* Profile.css */
.profile-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .avatar-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  @media (min-width: 768px) {
    .avatar-section {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
  
  .profile-info {
    width: 100%;
    max-width: 600px;
  }
  
  .points-section {
    margin-top: 20px;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .points-section {
      text-align: left;
    }
  }
  
  .delete-dialog {
    margin-top: 20px;
    text-align: center;
  }
  @media (min-width: 768px) {
  .name-location-container {
    flex-direction: row; /* Arrange name and location in a row for larger screens */
    align-items: center;
    gap: 8px; /* Space between first name, last name, and location */
  }
}
  .avatar-section img {
    width: 80px; /* Profile image size */
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  .avatar-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    margin-bottom: 20px;
  }
  
  @media (min-width: 768px) {
    .avatar-section {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  
  .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  
  .profile-info-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .level-points {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start; /* Align items to the left */
  }
  

  @media (min-width: 768px) {
    .level-points {
      flex-direction: row;
      justify-content: flex-start; /* Keep items closer together on larger screens */
      align-items: center;
      gap: 16px; /* Add space between elements */
    }
  }
  .points-chip {
    display: flex;
    align-items: center;
    padding: 4px 8px; /* Add padding for better alignment */
    max-width: 150px; /* Limit the width of the chip */
  }