* {
  font-family: Roboto;
}
.pointChip {
  height: 42px !important;
  width: 111px;
  border-radius: 100px !important;
  text-align: left;
  justify-content: flex-start !important;
}
.pointChip svg {
  margin-left: 2px !important;
}
.ratingSection{
  position: relative;
}
.done-feedback-sign{
  position: absolute;
  top: 4px;
  right: 7px;
}
.iconStyle {
  color: rgb(0, 202, 153) !important;
  font-size: 40px !important;
}
.cross-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.iconColor {
  color: #128d6f !important;
}
.menuIcon{
  color: rgba(38, 38, 38, 0.5) !important;
}
.customSelect .MuiSelect-select{
  background-color: white !important;
  display: flex !important;
  align-items: center !important;
}
.highlightedSidebar {
  color: rgb(0, 202, 153) !important;
  background-color: rgba(0, 202, 153, 0.2) !important;
  margin: 10px;
  font-weight: 600 !important;
  padding: 15px 31px;
}
.sidebarItem {
  color: rgba(38, 38, 38, 1) !important;
  /* font-size: 16px !important;
  font-weight: 600 !important; */
  margin-top: 10px;
  margin-bottom: 10px;
}
.sidebarItemHigh {
  color: #128d6f !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
.profileItemHeader {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: grey;
  padding: 10px 10px 0;
}
.emailPreference {
  font-weight: 700 !important ;
  font-size: 16px !important;
  color: black;
  padding: 10px 10px 0;
}
h2 {
  margin-top: 0;
  font-size: 32px;
}
.searchbar svg {
  color: rgba(38, 38, 38, 0.4);
}

.MuiDrawer-paper{
margin-top: 29px;
}
.mainBox {
  padding-left: 30px;
  width: 100%;
  padding-top: 7px;
 margin-top: 65px;

}
.deleteButton {
  color: red !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  text-transform: none !important;
}
.deleteText {
  font-size: 11px !important;
  color: dimgrey !important;
  padding: 0px 10px !important;
}
.bottomDiv {
  position: static;
  /* top: calc(100vh - -250px);
  transform: translateY(-100%); */
  padding-top: 100px;
  padding-bottom: 10px;
}
.profile-edit{
  position: relative;
}
.profile-edit .upload-img{
  position: absolute;
  width: fit-content;
  right: 0;
  bottom: 0;
}
.followers{
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 30px;
}
.followers > div{
  margin-right: 30px;
}
.followers h3{
  font-weight: bold;
  font-size: 16px;
  color: #262626;
  margin-right: 8px;
}
.followers p{
  font-weight: 500;
  font-size: 14px;
  color: #262626;
  opacity: 0.7;
}
.points-market{
  text-align: center;
}
 .card{
  padding: 35px 36px 0px 20px;
  height: auto;
  background: rgb(0 202 153 / 5%);
  border-radius: 25px;
  box-shadow: none;
  position: relative;
  margin-top: 40px;
}
.rate-comment .MuiChip-label{
  margin-top: auto;
  margin-bottom: auto;
}

.MuiAvatar-img{
  object-fit: fill !important;
}
 .card .bg-img{
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
}
/* .points-market .card .bg-img img{
  object-fit: contain;
} */
.card h2{
  font-weight: 700;
  font-size: 26px;
  color: #000000;
  margin-bottom: 20px;
  text-align: left;
}
 .card button{
  width: 100%;
  border-radius: 25px;
  height: 38px;
  width: 180px;
  background-color: #00CA99;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 10px;
  margin-right: 14px;
}
 .card .get-points{
  border: 1px solid #00CA99;
  background-color: transparent;
  color: #00CA99;
}
.profile-tags{
  margin-bottom: 30px;
}
.profile-level{
  margin-right: 24px;
  background: rgb(18 141 111 / 10%);
  border-radius: 25px;
  width: 110px;
}
.profile-level p{
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #128D6F;
}
.card-profile-lvl{
  background: rgb(163 173 207 / 10%);
  border-radius: 25px;
  width: 54px;
  display: flex;
  align-items: center;
}
.card-profile-lvl p{
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  color: #A4AED0;
}
.pointsStyle {
  font-size: 10px;
  color: rgba(38, 38, 38, 0.5);
  font-weight: 600;
}
.pointsStyle2 {
  font-size: 14px;
  color: rgba(38, 38, 38, 1);
  font-weight: 600;
}
.weburl-link {
  width: 400px;
  font-weight: 700;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(18, 141, 111, 1) !important  ;
  text-decoration: none !important;
  /* margin-top: 13px; */
  margin-right: 5px;
}
.weburl-link a {
  color: #128d6f;
  text-decoration: none;
}
.see_people_marks {
  color: rgba(33, 150, 243, 1) !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  text-decoration: none !important;
  margin: 10px !important;
}
.viewCount {
  background: none !important;
  font-size: 14px !important;
  color: #128d6f !important;
}
.viewCount span {
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
}
.viewCount svg{
  margin-left: 0 !important;
}
.commentDesc {
  margin: 16px 0 !important;
}

.tagChips {
  margin: 2px !important;
  color: #262626 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  background-color: rgba(243, 243, 243, 1) !important;
}
.tagChipsOutlined {
  margin: 2px !important;
  border: 2px solid rgba(0, 202, 153, 0.7) !important;
  color: rgba(38, 38, 38, 1) !important;
}
.cardContent {
  padding-top: 0px !important;
}
.appbar_style {
  background-color: white !important;
}
.MuiDrawer-paper{
  overflow:hidden !important;
}
.searchBox {
  margin-bottom: 18px !important;
}
.subHeader {
  font-weight: 600;
  font-size: 22px;
  padding: 28px 0px;
  padding-top: 16px;
  padding-bottom: 24px;
}
.subHeaderTwo {
  padding-top: 34px;
  padding-bottom: 24px;
}
.bussinessHeader {
  font-weight: 500 !important;
}
.connectButton {
  color: white !important;
  border-radius: 100px !important;
  margin: 30px 0px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  width: 220px;
  height: 48px;
  padding-top: 10px !important;
}
.removeButton {
  color: white !important;
  background-color: #ea2649 !important;
  border-radius: 100px !important;
  margin: 30px 0px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  width: 220px;
  height: 48px;
  padding-top: 10px !important;
}
button {
  box-shadow: none !important;
}
.signUpButton {
  color: white !important;
  border-radius: 100px !important;
  margin: 32px 0px 0px !important;
  padding: 8px 30px !important;
}
.businessAccountInputStyle {
  margin: 10px 0px !important;
}
.profileAvatar {
  width: 70px !important;
  height: 70px !important;
}
.paddingStyle {
  padding: 0px 10px !important;
}
.textarea_business {
  border-radius: 6px;
  width: 95% !important;
  margin: 0px 10px !important;
  height: 150 !important;
  padding: 10px !important;
  border: 1px solid #dedede;
  outline: 0px !important;
}

.textarea_business:focus-visible {
  outline: 0px !important;
}
.textarea_business_2{
  border-radius: 6px;
  width: 95% !important;
  height: 150 !important;
  padding: 10px !important;
  border: 1px solid #dedede;
  outline: 0px !important;
  resize: none !important;
}

.textarea_business_2:focus-visible {
  outline: 0px !important;
}

.d_block{
  visibility: visible;
  opacity: 1;
}
.d_none{
  visibility: hidden;
  opacity: 0;
  width:0;
}


.loginCard {
  /* position: relative !important;
  top: 40vh !important;
  transform: translateY(-50%) !important; */
  box-shadow: 0px 5px 26px 4px rgba(0, 0, 0, 0.05),
    0px 7px 9px -4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 459px;
  margin: 0 auto;
}
.loginCard .css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: none;
}
.signInHeader {
  display: flex;
}
.signIn {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin: 10px 0px !important;
  flex-grow: 1;
}
.hideButton {
  flex-grow: 1;
  font-weight: 600;
  text-align: end;
  color: rgba(33, 150, 243, 1) !important;
}
.commentHeader {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  padding: 18px 0px;
}
.dont_have_account {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: rgba(33, 150, 243, 1) !important;
  text-decoration: none !important;
  margin: 10px 0px !important;
}
.signin_with {
  width: 100%;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  padding: 17px !important;
  font-size: 12px;
}
.cant_signIn {
  width: 100%;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  text-decoration-color: rgba(0, 0, 0, 0.5) !important;
  text-underline-offset: 1px;
}
.terms_conds {
  /* width: 100%; */
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 400 !important;
  font-size: 16px;
  /* padding: 15px 0px 0px !important; */
  /* line-height: 23px; */
  text-decoration-color: rgba(0, 0, 0, 0.5) !important;
  text-underline-offset: 1px;
  margin-top: -4px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.terms_conds2 {
  width: 100%;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 400 !important;
  text-align: center;
  font-size: 16px;
  /* padding: 15px 0px 0px !important;
  line-height: 23px; */
  text-decoration-color: rgba(0, 0, 0, 0.5) !important;
  text-underline-offset: 1px;
  margin-top: -4px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.loginButtons {
  text-transform: none !important;
  padding: 17px 20px !important;
  font-size: 10px !important;
  border-radius: 100px !important;
  width: 100%;
  color: rgba(38, 38, 38, 1) !important;
  height: 48px;
  border: 1px solid rgba(38, 38, 38, 0.1) !important;
}
.divider {
  margin: 0px 10px !important;
}
.commentAvatar {
  width: 50px !important;
  height: 50px !important;
}
.commentItem {
  width: 240px;
  display: inline-flex;
  padding: 5px 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 8px;
}
.commentItem2 {
  width: 240px;
  display: inline-flex;
  padding: 20px 0px;
}
.commentIconStyle {
  color: rgb(0, 202, 153) !important;
  font-size: 25px !important;
}
.likeIcon :hover {
  color: lightcoral;
}
.button-rate:hover{
  opacity: 0.5;
  background-color: #128d6f !important;
}
.liked {
  color: lightcoral !important;
}
.ratingStats {
  padding: 0px 8px !important;
}
.iconActions {
  padding: 2px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
}
.dialogHeader {
  text-align: center;
  font-weight: 500 !important;
  padding-bottom: 0;
  color: #128d6f;
  font-size: 1.4em !important;
}
.dialogStyle {
  padding: 0px 18px 18px 18px !important;
}
.d-flex{
  display: flex;
}
.rateNumber {
  font-size: 22px !important;
  font-weight: 500 !important;
  padding: 0px 2px !important;
}
.percentStat {
  color: grey !important;
  margin: 0px 10px 0px 20px !important;
}
.seeStatsBtn {
  /* position: absolute !important; */
  width: 125px !important;
  margin-left: 20px !important;
  margin-top: 2px !important;
  padding-top: 3px;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #128d6f !important;
}
.userName {
  font-family: Roboto;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #262626;
}
.grey-box{
  background: #F8F8F8;
  padding:10px 24px;
  height:100%
}
.white-box{
  background-color: white;
  padding:10px 10px 24px;
  height: 150px;
}
.white-box-2{
  background-color: white;
  padding:16px 30px;
  display:flex;
  align-items: center;
  height:90px
}
.green-heading{
  color:#128D6F;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0px;
}
 .grey-text{
  font-size: 16px;
  color:#262626;
  font-weight: 500;
  text-align: center;
  margin-top: 5px;
}
.proRater {
  color: rgba(38, 38, 38, 0.5) !important;
  font-size: 14px;
  font-weight: 700;
}
.textMuted {
  color: rgba(38, 38, 38, 0.5) !important;
  font-size: 14px;
  font-weight: 700;
}
.profilePic {
  width: 36px !important;
  height: 36px !important;
}

.profileDesc {
  margin-left: 12px;
  margin-bottom: 38px;
  margin-top: 18px;

}

.profileDiv {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.headerDivider {
  margin-right: 24px !important;
  width: 1px;
  background-color: #e0e0e0;
}
.terms-point {
  /* margin-right: -13px;
  margin-left: -5px; */
  margin-top: 24px;
  color: rgba(38, 38, 38, 0.5);
  text-align: center;
}
.dataRateHeader {
  font-size: 22px !important;
  font-weight: 700 !important;
  flex-grow: 1;
  padding-left: 12px;
}
.desc-text {
  color: rgba(38, 38, 38, 0.87) !important;
  font-size: 16px !important;
}
.cardItem {
  box-shadow: 1;
  /* border: 1px solid rgba(0, 0, 0, 0.12) !important; */
  height: 350px;

  border-radius: 10px !important;
}
.MuiBox-root .css-19kzrtu{
padding: 0px !important;
}

.chipMainDiv {
  width: 180px;
  display: inline-flex;
  justify-content: center;
  margin-right: -10px;
}
.preference button, .editable button{
  color: #2196F3;
  font-weight: bold;
  text-transform: capitalize;
}
@media (max-width: 1370px){
  .bottomDiv {
    top: calc(100vh - -350px);
  }
}
@media (max-width: 1100px){
  .bottomDiv {
    position: static;
    transform: translateY(0);
  }
}
@media (max-width: 991px) {
  .profileDiv {
    width: auto;
  }
  .points-market{
    margin-left: 0;
  }
}
@media (max-width:1199px){
  .margin-bottom-md{
    margin-bottom: 15px !important;
  }
}
@media (max-width: 767px) {
  .mainBox {
    padding: 0px 30px;
  }
  .css-4aaimn-MuiDrawer-docked {
    width: 70px !important;
  }
  .css-4aaimn-MuiDrawer-docked .MuiDrawer-paper {
    width: 70px !important;
  }


  .css-197nixd-MuiButtonBase-root-MuiListItem-root {
    margin: 10px 4px !important;
    padding-left: 4px !important;
  }
  .iconColor {
    justify-content: center;
  }
  .sidebarItem {
    justify-content: center;
  }
  .sidebarItem {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
@media (max-width:625px){
  .dataRateHeader {
    width: 100px !important;
    padding-left: 0 !important;
    font-size: 16px !important;
  }
}
@media (max-width: 599px) {
  .mainBox {
    padding: 20px;
  }

}
@media (max-width: 575px) {
  .businessAccountInputStyle {
    width: 95% !important;
  }

  .css-4aaimn-MuiDrawer-docked .MuiDrawer-paper {
    width: 63px !important;
  }
  .css-4aaimn-MuiDrawer-docked {
    width: 63px !important;
  }
  .css-197nixd-MuiButtonBase-root-MuiListItem-root {
    padding-left: 0px !important;
  }
  .cardTags {
    font-size: 12px !important;
  }
  .weburl-link {
    margin: auto;
  }
  .ratingSection {
    justify-content: center;
    position: relative;
  }

  .seeStatsBtn {
    margin-left: 0 !important;
  }
  .desc-text {
    font-size: 13px !important;
  }
  .subHeader {
    font-size: 18px;
  }
}
.css-ryrseu-MuiRating-root{
  font-size: 1.25rem !important;
}
@media (max-width: 500px) {
  .mainBox {
    padding: 0 20px;
  }
  .white-box-2{
    justify-content: center;
  }
    .MuiDrawer-paper{
      padding-top: 0px;
      margin-top: 0px;
    }
  .chipMainDiv {
    display: inline-flex;
    justify-content: center;
    margin-right: 0;
    width: 100px;
  }
  .iconButtonHeaderIcon {
    padding-right: 5px !important;
  }
  .loginCard {
    width: 100%;
    margin: 0 15px !important;
  }
  .pointChip {
    height: 34px !important;
    width: 80px;
    border-radius: 100px !important;
    text-align: left;
    justify-content: flex-start !important;
  }
  /* .profileDiv{
    display: block;
  } */

  .iconStyle {
    color: rgb(0, 202, 153) !important;
    font-size: 30px !important;
  }
  .headerDivider {
    margin: 10px 12px !important;
    width: 1px;
    background-color: #e0e0e0;
  }
  .profileDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .proRater {
    font-size: 10px !important;
    text-align: center;
  }
  .userName {
    font-size: 12px !important;
    text-align: center;
  }
  .profilePic {
    margin-top: 5px;
  }
  .pointsStyle2 {
    font-size: 12px;
  }
  .textarea_business,.textarea_business_2 {
    width: 80% !important;
  }
}

@media (max-width: 400px) {
  .profileDiv2 {
    margin-left: 3rem;
  }
}
