/* General styles */
.plans-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
}

.plans-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
}

/* Grid container for the cards */
.plans-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
}

/* Individual plan card */
.plan-card {
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

/* Plan title and price */
.plan-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.plan-price {
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 1.5rem;
}

/* Features list */
.plan-features {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

.plan-features li {
  font-size: 0.95rem;
  color: #555;
  margin: 0.5rem 0;
}

/* Call-to-action button */
.plan-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.plan-button:hover {
  background-color: #555;
}
