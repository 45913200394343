.cardIntegration{
    position: absolute;
    right:0;
    width: 430px;
    z-index: 1;
    box-shadow: 0px 5px 26px 4px rgba(0, 0, 0, 0.05), 0px 7px 9px -4px rgba(0, 0, 0, 0.1);
}
.connectButton2{
    color: white !important;
  border-radius: 100px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding-top: 10px !important;
  width: 126px;
  height: 36px;
}
.connectButton3{
    color: white !important;
    border-radius: 100px !important;
}
.connectedButton{
    color: #128D6F !important;
    border-radius: 100px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    padding-top: 10px !important;
    width: 126px;
    height: 36px; 
    background-color: rgba(0,202,153,0.2) !important;
}
.customTabs .MuiTabs-indicator{
    display: none !important;
}
.tab-active-space{
    background-color: rgba(0,202,153,0.2) !important;
    border:1px solid #00CA99 !important;
    color:#262626 !important;
}